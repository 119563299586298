import React from "react"
import { graphql } from "gatsby"
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { formatDate } from 'src/util/dateUtil';

import Styles from "./index.module.scss"
import Layout from "src/components/layout"
import Seo from "src/components/seo"
import NewsHeader from "src/components/header"
import Footer from "src/components/footer"
import Button from "src/components/LinkedButton"
import Mainsection from "src/components/section/main"
import Spacer from "src/components/spacer"

// ページ全体
const NewsIndex = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const { title, image } = frontmatter

  return (
    <Layout>
      <Seo description={title} ogpImage={image} />
      <NewsHeader />
      <main>
        <Mainsection title="お知らせ">
          <Spacer isHalf />
          <News data={data} />
        </Mainsection>
      </main>
      <Footer />
    </Layout>
  )
}

// 記事全体
const News = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark
  const { date, tag, title, path } = frontmatter

  const Box = ({ children }) => (
    <>
      <div className="is-hidden-tablet box">{children}</div>
      <div className="is-hidden-mobile box p-6">{children}</div>
    </>
  )

  return (
    <>
      <div className="container">
        <Box>
          <Infomations tag={tag} title={title} date={date} path={path} />
          <Spacer isHalf />
          <div className={`content ${Styles.markdown}`} dangerouslySetInnerHTML={{ __html: html }} />
          <Spacer isHalf />
        </Box>
      </div>

      <Spacer isHalf />
      <div className="container has-text-centered">
        <Button to="/news" title="お知らせ一覧に戻る" />
      </div>
    </>
  )
}

// タイトルなど
const Infomations = ({ tag, title, date, path }) => {
  const Tag = ({ addClassName, addStyle = {} }) => (
    <div className={`tag is-rounded is-primary ${addClassName}`} style={{ fontWeight: "bold", letterSpacing: "0.075em", ...addStyle }}>{tag}</div>
  )

  return (
    <>
      {/* タグ */}
      <div className="mb-4">
        <Tag addClassName="is-hidden-tablet" />
        <Tag addClassName="is-hidden-mobile is-medium" addStyle={{ fontSize: "0.9rem", padding: "1rem 1.25rem" }} />
      </div>
      {/* タイトル */}
      <h1 className="has-text-weight-bold is-size-4 is-size-5-mobile" style={{ letterSpacing: "0.05em" }}>{title}</h1>
      {/* 日付 + SNSシェアボタン */}
      <div className="is-flex is-justify-content-space-between is-align-content-center mt-1">
        <p className="has-text-primary has-text-weight-bold is-size-7 is-size-7-mobile mt-3 ml-1" style={{ letterSpacing: "0.1rem" }}>{formatDate(date)}</p>
        <ShareButtons title={title} path={path} />
      </div>
      <hr className="mt-3" />
    </>
  )
}

// SNSシェアボタン
const ShareButtons = ({ title, path }) => {
  // シェア用データ
  const url = `https://natade-coco.com${path}`
  const text = `${title} / natadeCOCO公式サイト`
  // 各SNSの投稿用URL
  const twitterSharePath = `https://twitter.com/share?text=${text}%0a&url=${url}&hashtags=natadeCOCO`
  const facebookSharePath = `https://www.facebook.com/share.php?u=${url}`

  // ボタンテンプレート
  // - ボタンカラークラスと投稿用URLとアイコンを受け取って、画面サイズに応じたボタンを返す
  const ShareButton = ({ colorClass, sharePath, tooltipText = "シェア", children }) => {
    const Button = ({ className }) => (
      <Tippy content={tooltipText}>
        <button className={`button is-primary is-flex is-justify-content-center is-align-content-center ${className} ${colorClass}`} style={{ borderRadius: "100%" }}>
          <a target="_blank" rel="noopener noreferrer" href={sharePath}>
            {children}
          </a>
        </button>
      </Tippy>
    )
    return (
      <>
        <Button className="is-hidden-tablet is-small">{children}</Button>
        <Button className="is-hidden-mobile">{children}</Button>
      </>
    )
  }

  return (
    <div className="is-flex">
      <div className="mr-3">
        <ShareButton colorClass="is-twitter" sharePath={twitterSharePath} tooltipText="Twitterで共有">
          <span className="icon">
            <i className="fab fa-twitter fa-inverse"></i>
          </span>
        </ShareButton>
      </div>
      <div>
        <ShareButton colorClass="is-facebook" sharePath={facebookSharePath} tooltipText="Facebookで共有">
          <span className="icon">
            <i className="fab fa-facebook fa-inverse"></i>
          </span>
        </ShareButton>
      </div>
    </div>
  )
}

// // SNSシェアボタン (公式)
// const ShareButtons = ({ title, path }) => {
//   // https://publish.twitter.com/ から "Twitter Buttons" > "Share Button" で作成
//   const TwitterButton = ({ text }) => (
//     <div>
//       <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/share?ref_src=twsrc%5Etfw" className="twitter-share-button" data-size="large" data-text={text} data-hashtags="natadeCOCO" data-related="natadeCOCO" data-lang="ja" data-show-count="false">Tweet</a><script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>
//     </div>
//   )

//   // https://developers.facebook.com/docs/plugins/share-button で作成
//   const FacebookButton = ({ url }) => (
//     <div>
//       <div id="fb-root"></div>
//       <script async defer crossOrigin="anonymous" src="https://connect.facebook.net/ja_JP/sdk.js#xfbml=1&version=v11.0" nonce="7Aqm3PmH"></script>
//       <div className="fb-share-button" data-href={url} data-layout="button_count" data-size="large"><a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fnatade-coco.com%2F&amp;src=sdkpreparse" className="fb-xfbml-parse-ignore">シェア</a></div>
//     </div>
//   )

//   return (
//     <div className="is-flex">
//       <div className="mr-3"><TwitterButton text={`${title} | natadeCOCO公式サイト`} /></div>
//       <div><FacebookButton url={`https://natade-coco.com${path}`} /></div>
//     </div>
//   )
// }

export default NewsIndex

export const query = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date
        path
        tag
        title
        image
      }
    }
  }
`